import React from "react";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { ChakraProvider } from "@chakra-ui/react";
import { useApollo, useApolloAiaas } from "src/services/Apollo/Client";
import StripeElements from "src/services/Stripe";

import * as Sentry from "@sentry/node";

import customTheme from "src/utils/theme";
import { ApolloProvider } from "@apollo/client";
import { AuthProvider } from "src/shared/contexts/AuthContext";

import { initLocation, AIAAS_ROUTE } from "src/utils/constants/routes";

initLocation();

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
});

const App: React.FC<AppProps> = ({ Component, pageProps, ...props }) => {
  // This is needed because Next does not exposes the 'err' props in their AppProps type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const err = (props as any).err;
  const apolloClient = useApollo(pageProps.initialApolloState);
  const apolloClientAiaas = useApolloAiaas();
  const router = useRouter();
  const isAiaas =
    router.pathname.includes(AIAAS_ROUTE) ||
    router.pathname.includes("geniova") ||
    router.pathname.includes("straumann") ||
    router.pathname.includes("c4w") ||
    router.pathname.includes("globalD") ||
    router.pathname.includes("generic") ||
    router.pathname.includes("candid");

  return (
    <ApolloProvider client={isAiaas ? apolloClientAiaas : apolloClient}>
      <StripeElements>
        <AuthProvider>
          <ChakraProvider resetCSS theme={customTheme}>
            <Component {...pageProps} err={err} />
          </ChakraProvider>
        </AuthProvider>
      </StripeElements>
    </ApolloProvider>
  );
};

export default App;
